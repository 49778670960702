import { IProjects, ResponseJson } from './../models/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IActivityLink } from 'src/app/modules/dashboard/flat-details/models/flat-activity.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseService {
  currentProject : BehaviorSubject<IProjects | null> = new BehaviorSubject<IProjects | null>(null)
  
  apiUrl = environment.apiUrl + '/projects'

  constructor(
    protected override http: HttpClient,
    private router : Router
  ) {
    
    super(http)
    if(sessionStorage.getItem('currentProject')) {
      const projectId : any = JSON.parse(sessionStorage.getItem('currentProject') as string)
      this.currentProject.next(projectId);
    }

   }

  updateCurrentProject(project : IProjects) {
    sessionStorage.setItem('currentProject', JSON.stringify(project));
    this.currentProject.next(project);
    this.router.navigate(['/dashboard']);
  }


  getProjectsData() {
    return this.get(this.apiUrl)
  }

  getSignedUrl(attachmentId:string){
    return this.get<ResponseJson<any>>(`${this.apiUrl}/attachment/${attachmentId}`)
  }

  getActivityLinkSignedUrl(linkObject: IActivityLink) {
    return this.post<ResponseJson<any>>(`${this.apiUrl}/activity-attachment`, linkObject)
  }
}
